.megadraft-editor .sidebar__menu {
  left: -42px !important;
  transform: translateY(-8px);
  z-index: 2;
}

.megadraft-editor button:focus {
  outline: 0;
}

.megadraft-editor .sidemenu {
  display: flex !important;
  align-items: center;
  height: 50px;
  z-index: 1000;
  position: relative;
}

.megadraft-editor .sidemenu__button {
  flex-shrink: 0;
}

.megadraft-editor .sidemenu__items.sidemenu__items {
  display: none;
  max-height: none !important;
  margin-top: 0;
  margin-left: 20px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: auto;
  border-radius: 10px;
  padding: 0;
}

.megadraft-editor .sidemenu__items.sidemenu__items--open {
  display: flex;
}

.megadraft-editor .sidemenu__item.sidemenu__item {
  height: auto;
}

.megadraft-editor .sidemenu__item button {
  border-radius: 0;
}

.megadraft-editor .megadraft-block {
  padding-bottom: 0 !important;
}

.megadraft-editor .megadraft-block + .megadraft-block {
  padding-top: 20px !important;
}

#leadEditorWrapper .megadraft-editor .public-DraftEditorPlaceholder-hasFocus {
  display: block;
  margin-top: -24px;
}

.megadraft-editor .DraftEditor-root,
.megadraft-editor .toolbar {
  font-size: 16px !important;
  letter-spacing: 0.15008px !important;
}

.megadraft-editor .paragraph + .paragraph {
  margin-top: 10px !important;
}

.public-DraftEditorPlaceholder-root {
  color: rgb(117, 117, 117) !important;
}
